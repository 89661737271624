const util = {}

util.formatDate = function(time) {
    let date = new Date(time)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (month < 9) {
        month = '0' + month
    }
    return year + '-' + month + '-' + day
}

export default util