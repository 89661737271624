<template>
  <div class="kh_gain">
    <div class="kh_gain_bg">
      <img :src="basicData.msg_bg" alt="" style="width:100%" />
    </div>
    <div class="kh_gain_nav">
      <div class="nav_list">
        <div class="nav_list_head">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span class="el-breadcrumb__item" style="margin-right:8px"
              ><i class="el-icon-s-home"></i> 当前位置：</span
            >
            <el-breadcrumb-item
              v-for="(item, idx) in basicData.msg_head"
              :key="idx"
              :to="{ path: item.value }"
            >
              {{ item.key }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="nav_list_col" v-if="!currentId">
          <ul class="item" v-if="product.length != 0">
            <li
              v-for="(item, idx) in product"
              :key="idx"
              @click="goDetails(item)"
            >
              <div class="list_nav">
                <div class="list_nav_date">
                  <span class="date_span">{{
                    item.updatetime.slice(8, 10)
                  }}</span>
                  <span>{{ item.updatetime.slice(0, 7) }}</span>
                </div>
                <div class="list_nav_text">
                  <a
                    :href="
                      '#/kh_gain?currentIdx=' +
                        currentIdx +
                        '&currentId=' +
                        item.id
                    "
                    class="ellipsis"
                    >{{ item.title }}</a
                  >
                  <p class="two_ellipsis">{{ item.subtitle }}</p>
                </div>
              </div>
            </li>
            <onLoading :show="loading"></onLoading>
          </ul>
          <div class="details_empty" v-else>
            <img
              src="@/assets/image/xwdt/xwdt-data.webp"
              alt=""
              style="margin:0 auto"
            />
          </div>
          <div class="all-pagination">
            <el-pagination
              prev-text="上一页"
              next-text="下一页"
              @current-change="handleCurrentChange"
              :current-page.sync="form.page"
              background
              :page-size="10"
              layout="prev, pager, next,total"
              :total="total"
              v-if="total > 10"
            >
            </el-pagination>
            <span class="no_meet_conditions" v-else>暂无更多数据</span>
          </div>
        </div>
        <div class="nav_list_html" v-else v-html="sjData.maincontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllList, getAlDetail, getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
import util from '@/api/util'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      basicData: {},
      currentId: 0,
      currentIdx: 0,
      product: [],
      loading: false,
      total: 0,
      sjData: {},
      form: {
        page: 1,
        pagesize: 10,
        project: '',
        type_id: ''
      }
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.currentIdx = this.$route.query.currentIdx
        } else {
          this.currentIdx = 0
          this.form.type_id = ''
          this.allProduct(this.form)
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (that.$route.query.currentIdx) {
        that.currentIdx = that.$route.query.currentIdx
      } else {
        that.currentIdx = 0
      }
      if (that.$route.query.currentId) {
        that.currentId = that.$route.query.currentId
        that.goDetails({ id: that.currentId })
      } else {
        that.currentId = 0
      }
      let id = sessionStorage.getItem(that.changeData() + 'id')
      let project = sessionStorage.getItem(this.changeData() + 'project')
      that.form.project = project
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.basicData = res.data.data.基本信息
        }
      })
      that.allProduct(that.form)
    },
    switchList (e, i) {
      this.currentIdx = i
      this.currentId = 0
      this.form.type_id = e.id
      this.allProduct(that.form)
      if (this.basicData.msg_head != 0) {
        this.basicData.msg_head[2].key = e.name
      }
    },
    allProduct (e) {
      this.loading = true
      getAllList(e).then(res => {
        if (res.data.code == 1) {
          res.data.data.list.forEach(el => {
            if (el.updatetime) {
              el.updatetime = util.formatDate(el.updatetime)
            }
          })
          this.product = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    goDetails (e) {
      this.currentId = e.id
      this.sjData = {}
      getAlDetail(e.id, this.form.project).then(res => {
        if (res.data.code == 1) {
          this.sjData = res.data.data
        }
      })
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.allProduct(this.form)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kh_gain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .kh_gain_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .kh_gain_nav {
    margin: 1rem auto;
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    .nav_list {
      width: 100%;
      .nav_list_head {
        padding: 0.15rem 0;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
      }
      .nav_list_col {
        margin-top: 0.3rem;
        .item,
        .details_empty {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          position: relative;
          li {
            font-size: 0.18rem;
            vertical-align: middle;
            width: 100%;
            margin-bottom: 20px;
            background-color: #fff;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            .list_nav {
              display: flex;
              flex-wrap: wrap;
              position: relative;
              .list_nav_date {
                color: #555555;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0.3rem 0.3rem;
                background-color: RGB(222, 237, 255);
                color: #469cff;
                .date_span {
                  font-size: 0.4rem;
                  font-weight: 600;
                  margin-bottom: 0.05rem;
                }
              }
              .list_nav_text {
                width: calc(100% - 1.7rem);
                padding: 0.2rem;
                display: flex;
                flex-direction: column;
                font-size: 0.16rem;
                a {
                  font-weight: 600;
                  font-size: 0.24rem;
                }
                p {
                  margin-top: 0.1rem;
                  line-height: 1.5;
                  color: #666666;
                }
              }
            }
          }
          li:hover .list_nav .list_nav_date {
            color: #fff;
            background-color: #6495ed;
          }
        }
        .details_empty {
          background-color: #fff;
        }
      }
      .nav_list_html {
        margin-top: 0.3rem;
        line-height: 1.5;
        /deep/p img {
          margin: 0 auto !important;
          display: block;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
